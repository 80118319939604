html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
}

html {
  background-image: linear-gradient(to top, #0009, #0009), url("bg.0af488a5.webp");
  background-position: center;
  background-size: cover;
  font-family: Montserrat, serif;
}

#main {
  color: #ededed;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#profile-photo {
  width: 25vmin;
  height: 25vmin;
  opacity: .7;
  background: url("alp-coker.68337a42.webp") 0 0 / cover;
  border: 1vmin solid #1b1b1b;
  border-radius: 50%;
}

#name {
  margin-bottom: 5vmin;
  font-size: 2em;
}

#title {
  text-align: center;
  max-width: 80vmin;
  margin-bottom: 5vmin;
  font-size: 1.4em;
}

#links {
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.link-item {
  margin: 0 4vmin;
}

.fa {
  font-family: FontAwesome, serif;
  transform: translate(0);
}

li > a > i {
  font-size: 1.5em;
}

.fa-file:after {
  content: "CV";
  color: #ededed;
  font-size: .3em;
  font-weight: bolder;
  position: relative;
  bottom: .2em;
  right: 1.9em;
}

a, a:visited {
  color: #ededed;
  text-decoration: none;
}

#photo-credit {
  font-size: .7em;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

body.is-loading #main, body.is-loading #main > * {
  opacity: 0;
}

#main > * {
  transition: opacity 1s ease-in-out;
}

#main > :nth-child(2) {
  transition-delay: .25s;
}

#main > :nth-child(3) {
  transition-delay: .5s;
}

#main > :nth-child(4) {
  transition-delay: .75s;
}

#main > :nth-child(5) {
  transition-delay: 1s;
}

#main > :nth-child(6) {
  transition-delay: 1.25s;
}

#main > :nth-child(7) {
  transition-delay: 1.5s;
}

#main > :nth-child(8) {
  transition-delay: 1.75s;
}

/*# sourceMappingURL=index.3dd1f349.css.map */
